import React from 'react';
import { APP_STORE_LINKS } from './data';
// import { amplitudeEvents, trackEvent } from 'utils/amplitude';
import { useAuth0 } from '@auth0/auth0-react';

const Links = () => {
  const { user } = useAuth0();
  // const trackStoreEvent = (e, title) => {
  //   e.stopPropagation();
  //   if (title === 'Google Store') {
  //     trackEvent(amplitudeEvents.GOOGLE_PLAY_CLICK, {
  //       email_address: user?.email,
  //     });
  //   }
  // };
  return (
    <div className="store-button">
      {APP_STORE_LINKS.map((menu, index) => (
        // <span onClick={e => trackStoreEvent(e, menu.title)} key={index}>
        <span key={index}>
          <a
            href={menu.path}
            target="_blank"
            rel="noreferrer"
            title={menu.title}
          >
            <img src={menu.icon} alt={menu.title} />
          </a>
        </span>
      ))}
    </div>
  );
};
export default Links;

import React from 'react';
import { Link } from 'gatsby';
import { externalLinkPattern } from 'shared/helpers/patterns';
import { useAuth0 } from '@auth0/auth0-react';
// import { amplitudeEvents, trackEvent } from 'utils/amplitude';

const Menus = ({ list }) => {
  const { isAuthenticated, user } = useAuth0();

  const renderListItems = list => {
    return (
      list?.length &&
      list.map(({ title, slug, viewMode, id }) => {
        if (isAuthenticated) {
          if (viewMode.includes('Logged In')) {
            return renderItemsAfterCheck(title, id, slug);
          } else {
            return null;
          }
        } else {
          if (viewMode.includes('Visitor')) {
            return renderItemsAfterCheck(title, id, slug);
          } else {
            return null;
          }
        }
      })
    );
  };
  // const trackEvents = title => {
  //   const eventProperties = { email_address: user?.email };
  //   if (title.toLowerCase() === 'about us') {
  //     trackEvent(amplitudeEvents.VIEW_ABOUT_US, eventProperties);
  //   } else if (title.toLowerCase() === 'media') {
  //     trackEvent(amplitudeEvents.VIEW_MEDIA, eventProperties);
  //   }
  // };
  const renderItemsAfterCheck = (title, id, slug) => {
    const isLink = slug ? externalLinkPattern.test(slug) : false;
    return (
      <li key={id}>
        {isLink ? (
          <a
            href={slug}
            target="_blank"
            rel="noreferrer"
            className="letterSpace"
            // onClick={() => trackEvents(title)}
          >
            {title}
          </a>
        ) : (
          <Link
            to={slug}
            // onClick={() => trackEvents(title)}
            className="letterSpace"
          >
            {title}
          </Link>
        )}
      </li>
    );
  };

  return <>{renderListItems(list)}</>;
};

export default Menus;

import React, { Fragment } from 'react';
import { CONTACT_INFO } from './data';
import CopyClipboard from 'components/CopyClipboard';
import PhoneNumber from 'components/PhoneNumber';
import GCO_Logo from 'static/svg/GCO_Logo.svg';
// import { amplitudeEvents, trackEvent } from 'utils/amplitude';
// import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'gatsby';

const { telephone, email, telWithCountryCode } = CONTACT_INFO;

const Address = () => {
  // const { user } = useAuth0();
  // const eventProperties = { email_address: user?.email };
  // const trackEmail = () => {
  //   trackEvent(amplitudeEvents.EMAIL_CLICK, eventProperties);
  //   window.fbq('track', 'Email');
  // };
  // const trackPhone = () => {
  //   trackEvent(amplitudeEvents.PHONE_CLICK, eventProperties);
  //   window.fbq('track', 'Phone Call');
  // };
  const mailtoLink = `mailto:${email}?subject=${''}&body=${''}`;

  return (
    <Fragment>
      <div>
        <h2 className="brand-heading" title="GoodingCo logo">
          <div
            className="footer-logo"
            style={{ backgroundImage: `url(${GCO_Logo})` }}
          ></div>
        </h2>
        <p>Auctions and Brokerage</p>
      </div>
      <div>
        <p>
          {/* <a onClick={trackPhone} href={`tel:${telWithCountryCode}`}> */}
          <a href={`tel:${telWithCountryCode}`}>
            {<PhoneNumber number={telephone}></PhoneNumber>}
          </a>
        </p>
        <p className="footer-email-mobile">
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        {/* <div onClick={trackEmail} className="footer-email-desktop"> */}
        <div className="footer-email-desktop">
          <a href={mailtoLink}>{email}</a>
          {/* <CopyClipboard id="footerEmail" text={email} /> */}
        </div>
      </div>
    </Fragment>
  );
};
export default Address;

import React from 'react';
import { Link } from 'gatsby';
import styles from './desktop.module.scss';
// import * as amplitude from '@amplitude/analytics-browser';
import { useAuth0 } from '@auth0/auth0-react';
const HeaderDropDown = ({ title, list, handleClick }) => {
  return (
    <li onClick={() => handleClick(title)} className={styles.actionMenu}>
      <div>{title}</div>
      <ul className={styles.desktopSubMenu}>
        {list?.length &&
          list.map(({ title, slug, id }) => {
            return (
              <li className={styles.subMenu} key={id}>
                <Link activeClassName={styles.active} to={slug}>
                  {title}
                </Link>
              </li>
            );
          })}
      </ul>
    </li>
  );
};
export default HeaderDropDown;

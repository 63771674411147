import React from 'react';
import _ from 'lodash';
import { Link } from 'gatsby';
import { useAuth0, User } from '@auth0/auth0-react';
import Button from 'components/Button';
import NavItems from '../NavItems';
import MyAccount from '../MyAccount';
import CloseIcon from 'svg/CloseIcon';
import GoodingLogo from 'static/svg/GoodingLogo';
import { BLUE } from 'utils/colorsConstants';
import styles from './sideMenu.module.scss';
// import * as amplitude from '@amplitude/analytics-browser';
// import { trackLognIn } from 'utils/amplitude';

const SideMenu = ({ closeSideMenu, isOpen }) => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  // if (isAuthenticated) {
  //   amplitude.setGroup('user_email', user?.email);
  //   trackLognIn(user?.email);
  //   amplitude.setUserId(user?.email);
  // }
  return (
    <div
      className={`${styles.sideMenuWrapper} ${
        isOpen ? styles.openSideMenuWrapper : ''
      }`}
      role="navigation"
    >
      <div className={styles.menuHeader}>
        <Link to="/" title="link to home page">
          <GoodingLogo color={BLUE} prefix="sideMenu" />
        </Link>

        <Button
          title="close menu"
          handleClick={() => _.isFunction(closeSideMenu) && closeSideMenu()}
        >
          <CloseIcon color="silver" />
        </Button>
      </div>
      <ul>
        <li>
          {isAuthenticated ? (
            <MyAccount />
          ) : (
            <Button
              handleClick={() => {
                localStorage.setItem(
                  'currentRoute',
                  `${window.location.pathname}${window.location.search}`
                );
                // amplitude.track('Click on Log In/Sign-up');
                localStorage.setItem('login', 'isLoggingIn');
                loginWithRedirect();
              }}
            >
              LOGIN / SIGN UP
            </Button>
          )}
        </li>
        <NavItems />
      </ul>
    </div>
  );
};

export default SideMenu;

import React from 'react';
import Link from 'components/Link/Link';
import { useAuth0 } from '@auth0/auth0-react';
import { useStaticQueryGetNavItems } from 'shared/queries/navigations';
import HeaderDropDown from './DropDown';
import { Roles, RolesKey } from 'utils/RolesConstants';
import styles from './desktop.module.scss';
// import * as amplitude from '@amplitude/analytics-browser';
// import { amplitudeEvents, trackEvent } from 'utils/amplitude';

export default () => {
  const { isAuthenticated, user } = useAuth0();
  const { headerNavItems } = useStaticQueryGetNavItems();
  // const handleTrack = navTitle => {
  //   const eventProperties = {
  //     email_address: isAuthenticated ? user?.email : '',
  //   };
  //   if (navTitle === 'LOTS') {
  //     trackEvent(amplitudeEvents.VIEW_LOTS_LIST_PAGE, eventProperties);
  //   } else if (navTitle === 'PRIVATE SALES') {
  //     trackEvent(amplitudeEvents.VIEW_PRIVATE_SALES_PAGE, eventProperties);
  //   } else if (navTitle === 'CONSIGN') {
  //     trackEvent(amplitudeEvents.VIEW_CONSIGNMENT_PAGE, eventProperties);
  //   }
  // };
  const renderNavigationItems = headerNavItems =>
    headerNavItems?.length &&
    headerNavItems.map(({ title, subElements, id, viewMode, slug }, index) => {
      const menu =
        viewMode.includes('Visitor') ||
        (isAuthenticated && viewMode.includes('Logged In')) ||
        (user?.[RolesKey].includes(Roles.kioskAdmin) &&
          viewMode.includes(Roles.kioskAdmin));

      if (menu) {
        return renderItemsAfterCheck(subElements, id, title, slug, index);
      } else {
        return null;
      }
    });

  const renderItemsAfterCheck = (subElements, id, title, slug, index) =>
    subElements ? (
      <HeaderDropDown
        // handleClick={handleTrack}
        key={id}
        id={index}
        list={subElements}
        title={title}
      />
    ) : (
      // <li onClick={() => handleTrack(title)} key={id}>
      <li key={id}>
        <Link activeClassName={styles.active} to={slug}>
          {title}
        </Link>
      </li>
    );
  return (
    <ul className={`${styles.navWrapper} mr-auto`}>
      {renderNavigationItems(headerNavItems)}
    </ul>
  );
};
